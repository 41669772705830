import React from "react"
import WhatLayout from "../../components/WhatLayout";
import bild01 from "../../images/what/gayart/angel.png";
import bild02 from "../../images/what/gayart/fallen-angels.png";
import bild03 from "../../images/what/gayart/kings-without-queens.png";
import bild04 from "../../images/what/gayart/phoenix.png";
import bild05 from "../../images/what/gayart/rainbowsplash.png";
import bild06 from "../../images/what/gayart/bunny.png";
import bild07 from "../../images/what/gayart/devil-in-the-detail.png";
import bild08 from "../../images/what/gayart/dream-about-ocean.png";
import bild09 from "../../images/what/gayart/medusaboys.png";
import bild10 from "../../images/what/gayart/prince-of-bordeaux.png";
import bild11 from "../../images/what/gayart/rainbowdancer.png";
import bild12 from "../../images/what/gayart/schuetze-2020.png";
import bild13 from "../../images/what/gayart/skorpion-2020.png";
import bild14 from "../../images/what/gayart/steinbock-2021.png";
import bild15 from "../../images/what/gayart/stier-2020.png";
import bild16 from "../../images/what/gayart/wassermann-2021.png";
import bild17 from "../../images/what/gayart/widder-2021.png";
import bild18 from "../../images/what/gayart/your-present.png";

const Page = () => {

  return (
    <WhatLayout
      header={"Gayart"}
      images={[
        bild01,
        bild02,
        bild03,
        bild04,
        bild05,
        bild06,
        bild07,
        bild08,
        bild09,
        bild10,
        bild11,
        bild12,
        bild13,
        bild14,
        bild15,
        bild16,
        bild17,
        bild18
      ]}
    />
  );

};

export default Page;
